var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-0",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9 mx-10"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v("車両情報の編集")]), _c('span', [_vm._v("車両情報の編集を行います。")])])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 車両クラス ")]), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "placeholder": "車両クラスの情報を入力してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.car.class,
      callback: function callback($$v) {
        _vm.$set(_vm.car, "class", $$v);
      },
      expression: "car.class"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 車両番号 ")]), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "placeholder": "車両番号を入力してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.car.number,
      callback: function callback($$v) {
        _vm.$set(_vm.car, "number", $$v);
      },
      expression: "car.number"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 車両購入日 ")]), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-dialog', {
    ref: "vehiclePurchaseDate",
    attrs: {
      "return-value": _vm.car.purchase_date,
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.car, "purchase_date", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.car, "purchase_date", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "form-text",
          attrs: {
            "readonly": "",
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": "0000 年 00 月 00 日 ",
            "disabled": _vm.disableForm
          },
          model: {
            value: _vm.car.purchase_date,
            callback: function callback($$v) {
              _vm.$set(_vm.car, "purchase_date", $$v);
            },
            expression: "car.purchase_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.modal.purchaseDate,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "purchaseDate", $$v);
      },
      expression: "modal.purchaseDate"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "scrollable": "",
      "day-format": _vm.formatDate
    },
    model: {
      value: _vm.car.purchase_date,
      callback: function callback($$v) {
        _vm.$set(_vm.car, "purchase_date", $$v);
      },
      expression: "car.purchase_date"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.modal.purchaseDate = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.vehiclePurchaseDate.save(_vm.car.purchase_date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 走行距離 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "placeholder": "00,000",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.car.mileage,
      callback: function callback($$v) {
        _vm.$set(_vm.car, "mileage", $$v);
      },
      expression: "car.mileage"
    }
  })], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" km ")])], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 車検 ")]), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-dialog', {
    ref: "vehicleinspectionDate",
    attrs: {
      "return-value": _vm.car.inspection_date,
      "width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        return _vm.$set(_vm.car, "inspection_date", $event);
      },
      "update:return-value": function updateReturnValue($event) {
        return _vm.$set(_vm.car, "inspection_date", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "form-text",
          attrs: {
            "readonly": "",
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": "0000 年 00 月 00 日 ",
            "disabled": _vm.disableForm
          },
          model: {
            value: _vm.car.inspection_date,
            callback: function callback($$v) {
              _vm.$set(_vm.car, "inspection_date", $$v);
            },
            expression: "car.inspection_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.modal.inspectionDate,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "inspectionDate", $$v);
      },
      expression: "modal.inspectionDate"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "scrollable": "",
      "day-format": _vm.formatDate
    },
    model: {
      value: _vm.car.inspection_date,
      callback: function callback($$v) {
        _vm.$set(_vm.car, "inspection_date", $$v);
      },
      expression: "car.inspection_date"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.modal.inspectionDate = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.vehicleinspectionDate.save(_vm.car.inspection_date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" メモ ")]), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "placeholder": "車両に関するメモを入力してください。",
      "disabled": _vm.disableForm
    },
    model: {
      value: _vm.car.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.car, "memo", _vm._n($$v));
      },
      expression: "car.memo"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "create-task-footer px-6"
  }, [_c('v-row', {
    staticClass: "justify-end"
  }, [_c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "rounded": "",
      "min-width": "150",
      "color": "#AD4545",
      "loading": _vm.loading,
      "disabled": _vm.disableForm
    },
    on: {
      "click": function click($event) {
        return _vm.deleteItem();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" $trash ")]), _vm._v(" 削除する ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-8",
    attrs: {
      "rounded": "",
      "min-width": "150",
      "text": "",
      "color": "#828282"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" キャンセル ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "min-width": "150",
      "color": "#4F55A7",
      "loading": _vm.loading,
      "disabled": _vm.disableForm
    },
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" $carSolid ")]), _vm._v(" 車両情報を更新する ")], 1)], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }