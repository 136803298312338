<template>
  <v-card class="pa-0" :loading="loading">
    <v-card-text class="pa-0">
      <div class="pa-9 pa-md-6 pa-lg-9 mx-10">
        <v-container>
          <v-row class="mb-2">
            <v-col cols="12">
              <h3 class="page-title font-weight-bold">車両情報の編集</h3>
              <span>車両情報の編集を行います。</span>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col cols="3" class="text-right">
              車両クラス
            </v-col>
            <v-col cols="7">
              <v-text-field
                outlined
                hide-details
                dense
                placeholder="車両クラスの情報を入力してください。"
                v-model="car.class"
                :disabled="disableForm"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="text-right">
              車両番号
            </v-col>
            <v-col cols="7">
              <v-text-field
                outlined
                hide-details
                dense
                placeholder="車両番号を入力してください。"
                v-model="car.number"
                :disabled="disableForm"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="text-right">
              車両購入日
            </v-col>
            <v-col cols="7">
              <v-dialog
                ref="vehiclePurchaseDate"
                v-model="modal.purchaseDate"
                :return-value.sync="car.purchase_date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="car.purchase_date"
                    readonly
                    class="form-text"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details
                    outlined
                    placeholder="0000 年 00 月 00 日 "
                    :disabled="disableForm"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="car.purchase_date"
                  scrollable
                  :day-format="formatDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal.purchaseDate = false"
                  >
                    キャンセル
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.vehiclePurchaseDate.save(car.purchase_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="text-right">
              走行距離
            </v-col>
            <v-col cols="auto">
              <v-text-field
                outlined
                hide-details
                dense
                placeholder="00,000"
                v-model="car.mileage"
                :disabled="disableForm"
              >
              </v-text-field>
            </v-col>
            <v-col cols="auto" class="flex-grow-1">
              km
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="text-right">
              車検
            </v-col>
            <v-col cols="7">
              <v-dialog
                ref="vehicleinspectionDate"
                v-model="modal.inspectionDate"
                :return-value.sync="car.inspection_date"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="car.inspection_date"
                    readonly
                    class="form-text"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details
                    outlined
                    placeholder="0000 年 00 月 00 日 "
                    :disabled="disableForm"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="car.inspection_date"
                  scrollable
                  :day-format="formatDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal.inspectionDate = false"
                  >
                    キャンセル
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.vehicleinspectionDate.save(car.inspection_date)
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="3" class="text-right">
              メモ
            </v-col>
            <v-col cols="7">
              <v-textarea
                outlined
                hide-details
                dense
                placeholder="車両に関するメモを入力してください。"
                v-model.number="car.memo"
                :disabled="disableForm"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="create-task-footer px-6">
        <v-row class="justify-end">
          <v-col cols="auto" class="flex-grow-1">
            <v-btn
              text
              rounded
              min-width="150"
              color="#AD4545"
              @click="deleteItem()"
              :loading="loading"
              :disabled="disableForm"
            >
              <v-icon left>
                $trash
              </v-icon>
              削除する
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="mr-8"
              rounded
              min-width="150"
              text
              color="#828282"
              @click="$router.back()"
            >
              キャンセル
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              min-width="150"
              color="#4F55A7"
              class="white--text"
              @click="submit()"
              :loading="loading"
              :disabled="disableForm"
            >
              <v-icon left>
                $carSolid
              </v-icon>
              車両情報を更新する
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Edit',
  watch: {
    'car.mileage': function(val) {
      const result = val.toString().replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.car.mileage = result);
    }
  },
  created() {
    this.getDataFromApi()
  },
  computed: {
    ...mapGetters(['getCar']),
    disableForm() {
      return this.loading
    }
  },
  data() {
    return {
      modal: {
        purchaseDate: false,
        inspectionDate: false
      },
      car: {
        class: '',
        number: '',
        mileage: 0,
        purchase_date: dayjs().format('YYYY-MM-DD'),
        inspection_date: dayjs().format('YYYY-MM-DD'),
        memo: ''
      },
      loading: false
    }
  },
  methods: {
    ...mapMutations(['showModal']),
    async getDataFromApi() {
      this.loading = true
      await this.$store.dispatch('CAR_GET', { id: this.$route.params.id })
      this.car.class = this.getCar.car_class
      this.car.number = this.getCar.number_plate
      this.car.mileage = this.getCar.miles.split(',').join('')
      this.car.purchase_date = this.getCar.date_of_purchase
      this.car.inspection_date = this.getCar.date_of_next_investigation
      this.car.memo = this.getCar.memo
      this.loading = false
    },
    async submit() {
      this.loading = true
      let formData = {}
      formData.id = this.$route.params.id
      formData.car_class = this.car.class
      formData.number_plate = this.car.number
      formData.miles = this.car.mileage
      formData.date_of_purchase = this.car.purchase_date
      formData.date_of_next_investigation = this.car.inspection_date
      formData.memo = this.car.memo
      await this.$store.dispatch('CAR_UPDATE', formData).then(response => {
        if (response.status === 200) {
          this.$router.push({
            name: 'CarManagement'
          })
        }
      })
    },
    deleteItem() {
      this.showModal({
        title: '',
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },

    async processDelete() {
      this.loading = true
      await this.$store
        .dispatch('CAR_DELETE', { id: this.$route.params.id })
        .then(response => {
          if (response.status === 200) {
            this.$router.push({
              name: 'CarManagement'
            })
          }
        })
      this.loading = false
    },
    formatDate(e) {
      return dayjs(e).format('D')
    }
  }
}
</script>

<style lang="scss" src="./Edit.scss" scoped></style>
